export const getToken = () => localStorage.getItem('access_token');

export const redirectToLogin = () => {
  window.location.href = '/login';
};

export const getUrlParam = (key: string) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(key);
};

export const logout = () => {
  localStorage.removeItem('access_token');
  redirectToLogin();
};
