import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import { Suspense, lazy } from 'react';

import { getToken } from '@/utils';

const baseRoute = process.env.REACT_APP_BASE_ROUTE;

const Login = lazy(() => import('@/views/Login'));
const Portal = lazy(() => import('@/views/Portal'));
const Welcome = lazy(() => import('@/views/Welcome'));
const Layout = lazy(() => import('@/components/Layout'));
const PageNotFound = lazy(() => import('@/views/PageNotFound'));

const LAYOUT_ROUTES: {
  [key: string]: JSX.Element
} = {
  '/': <Redirect to="/welcome" />,
  '/welcome': (
    <Layout>
      <Welcome />
    </Layout>
  ),
  '/shield': (
    <Layout>
      <iframe id="shield-iframe" src="http://shield.baixing.cn" title="shield" />
    </Layout>
  ),
  '/**': (
    <Layout>
      <div id="container" />
    </Layout>
  ),
};

const WITHOUT_LAYOUT_ROUTES: {
  [key: string]: JSX.Element
} = {
  '/login': <Login />,
  '/wx-portal': <Portal platform="WX" />,
  '/fs-portal': <Portal platform="FS" />,
};

function App() {
  return (
    <div className="App">
      <Router basename={baseRoute}>
        <Suspense fallback={<div className="loading">Loading...</div>}>
          <Switch>
            <Route
              exact
              path={Object.keys(WITHOUT_LAYOUT_ROUTES)}
              render={({ match }) => WITHOUT_LAYOUT_ROUTES[match.path]}
            />
            {getToken() && (
              <Route
                exact
                path={Object.keys(LAYOUT_ROUTES)}
                render={({ match }) => LAYOUT_ROUTES[match.path]}
              />
            )}
            {!getToken() && <Redirect to="/login" />}
            <Route path="**" render={() => <PageNotFound />} />
          </Switch>
        </Suspense>
      </Router>
    </div>
  );
}

export default App;
