import { StrictMode } from 'react';
import ReactDOM from 'react-dom';

import App from './App';

import 'antd/dist/antd.less';
import '@/assets/less/app.less';

ReactDOM.render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById('root'),
);
